import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { MailIcon, PhoneIcon } from '@heroicons/react/outline';
import Layout from '../components/layout';
import Seo from '../components/seo';

const ShowJumping = function () {
  return (
    <Layout>
      <Seo
        title="Show Jumping Lessons"
        description="High level coaching and training schooling for showing all the way up to the A circuit."
      />
      <div>
        <div className="relative max-w-7xl mx-auto pt-16 px-4 sm:px-6 lg:px-8 ">
          <div className="mx-auto text-base max-w-prose lg:hidden  ">
            <div>
              <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
                HELD YEAR ROUND
              </h2>
              <h1 className="heading-serif">
                Lessons, Leasing, and Training Programs
              </h1>
            </div>
          </div>
          <div className="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none ">
                <StaticImage
                  src="../images/lessons.png"
                  quality={95}
                  formats={['AUTO', 'WEBP', 'AVIF']}
                  alt="Southern Cross Showjumpers"
                  loading="eager"
                  className="rounded-lg shadow-lg object-cover object-center"
                />
              </div>
            </div>
            <div className="mt-8 lg:mt-0 ">
              <div className="md:max-w-2xl pl-2 lg:p-0 mx-auto hidden lg:grid">
                <h2 className="text-base text-brand-blue-100 font-semibold tracking-wide uppercase">
                  HELD YEAR ROUND
                </h2>
                <h3 className="heading-serif">Lessons</h3>
              </div>
              <div className="mt-5 prose prose-lg prose-indigo text-gray-500 mx-auto  md:max-w-xl lg:row-start-1 lg:col-start-1">
                <p>
                  At Southern Cross we believe there is knowledge that is power
                  - we know it takes different ideas and strengths to make a
                  successful partnership between you and your horse. Our
                  diversified programs strive to teach the rider to know how to
                  know rather than be taught what to know.
                </p>
                <p>
                  From schooling level showing all the way up to the A circuit,
                  Southern Cross has the horses for lease and the coaches that
                  you need.
                </p>
                <p>
                  We are proud of the high level of coaching and training that
                  can be provided, in a no- drama environment.
                </p>
                <p>
                  You will notice our programs have proven success – both in the
                  high placings at each show and the smile on the students
                  faces. The sense of accomplishment that the students feel is
                  unparalleled.
                </p>
                <p>
                  We are focused on building an exceptional show team. Supported
                  by a fantastic barn family, those who are leasing and showing
                  can learn and grow in an enriching environment, knowing that
                  their horses are getting the very best care at all times.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto md:max-w-none md:grid md:grid-cols-2 md:gap-8">
            <div>
              <h3 className="heading-serif">Beginner and Schooling Level</h3>
              <div className="mt-3">
                <p className="text-lg text-gray-500">
                  For information on the beginner and schooling level lessons,
                  please contact{' '}
                  <span className="text-brand-blue-100 font-semibold tracking-wide">
                    Fiona Borbely
                  </span>
                </p>
              </div>
              <div className="mt-9">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <PhoneIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>519-717-8438</p>
                  </div>
                </div>
                <div className="mt-6 flex">
                  <div className="flex-shrink-0">
                    <MailIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>fionaborbelyequestrian@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 sm:mt-16 md:mt-0">
              <h3 className="heading-serif">A-Circuit Riding & Training</h3>
              <div className="mt-3">
                <p className="text-lg text-gray-500">
                  For information on sales and A circuit riding and training
                  programs, please contact{' '}
                  <span className="text-brand-blue-100 font-semibold tracking-wide">
                    Dr. Brenda
                  </span>
                </p>
              </div>
              <div className="mt-9">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <PhoneIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>519-732-8265</p>
                  </div>
                </div>
                <div className="mt-6 flex">
                  <div className="flex-shrink-0">
                    <MailIcon
                      className="h-6 w-6 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 text-base text-gray-500">
                    <p>southerncrossef@gmail.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ShowJumping;
